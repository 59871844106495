import "./index.css";
import Button from "../../../components/buttons";
import { useNavigate, useParams } from "react-router-dom";
import {} from "../../../models/login";
import { toast } from "react-toastify";
import { FormGeneral } from "../../../components/form/formGeneral";
import { useState } from "react";
import api from "../../../conections/axios";
import { formProduct } from "../../../forms/product";
import { ProductSchema } from "../../../shemas/Product";
import { createProduct } from "../../../models/ManagerProducts";

interface FormProductInterface {
  title: string;
  description: string;
}

const FormProduct = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { groupId } = useParams();

  const handleCreateProduct = async (values: FormProductInterface) => {
    setLoading(true);
    try {
      const response = await createProduct({
        title: values.title,
        description: values.description,
        groupId: Number(groupId) || 0,
      });
      if (response) {
        toast.success("Cadastrado com sucesso!");
        navigate(-1);
      }
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao tentar cadastrar produto, contacte o suporte"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="sub-container ">
        <FormGeneral
          initialValues={{}}
          Shema={ProductSchema}
          form={formProduct}
          titleButtonAction="Cadastrar"
          title={"Cadastre um novo Produto"}
          loading={loading}
          onSubmit={handleCreateProduct}
        />
      </div>
    </div>
  );
};

export default FormProduct;
