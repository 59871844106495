import { useEffect, useState } from "react";
import useRemoteConfigValue from "../../hooks/remoteConfig";

const HeaderCheckout = () => {
  const isActiveForBuy = useRemoteConfigValue("isActiveForBuy");

  const calculateTimeLeft = () => {
    const now: any = new Date();
    const nextTargetDate: any = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 2,
      0,
      0,
      0
    );

    const timeLeft = nextTargetDate - now;
    return timeLeft > 0 ? timeLeft : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();

      if (newTimeLeft === 0) {
        const now: any = new Date();
        const newTargetDate: any = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 2,
          0,
          0,
          0
        );

        setTimeLeft(newTargetDate - now);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000);

    // Limpa o temporizador quando o componente é desmontado
    return () => clearInterval(timerId);
  }, []);

  const formatTime = (milliseconds: any) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const days = Math.floor(totalHours / 24);

    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    return `${days}d ${hours < 10 ? `0${hours}` : hours}h ${
      minutes < 10 ? `0${minutes}` : minutes
    }m ${seconds < 10 ? `0${seconds}` : seconds}s`;
  };

  return (
    <div className="header">
      <span>Princesa Maria Orlandina</span>
      {isActiveForBuy && <span>ENCERRA EM {formatTime(timeLeft)}</span>}
    </div>
  );
};

export default HeaderCheckout;
