import { styled } from "styled-components";
export interface CircleProps {
  number?: number; // Número a ser exibido dentro do círculo
  borderColor?: string; // Cor da borda do círculo
  backgroundColor?: string; // Cor do fundo do círculo
  textColor?: string; // Cor do texto
  style?: React.CSSProperties; // Estilos adicionais
}

export const CircleContainer = styled.div<CircleProps>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.borderColor || "rgba(0, 0, 0, 0.5)"}; // Borda com cor do tema ou padrão
  background-color: ${(props) =>
    props.backgroundColor ||
    "rgba(0, 0, 0, 0.2)"}; // Fundo com cor do tema ou padrão
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${(props) => props.textColor || "black"}; // Cor do texto
  font-weight: bold;
  padding: 20px;
  ${(props) => props.style && { ...props.style }}// Aplica estilos adicionais
`;
