// Circle.tsx
import React from "react";
import { useTheme } from "styled-components";
import { TextMinimum } from "../texts/TextStyles";
import { CircleContainer, CircleProps } from "./ContainerButtonClick";

const Circle: React.FC<CircleProps> = ({
  number = 0,
  borderColor,
  backgroundColor,
  textColor,
  style,
}) => {
  const theme = useTheme();
  return (
    <>
      <CircleContainer
        borderColor={theme.borderColor}
        backgroundColor={theme.containerBackground}
        textColor={textColor}
        style={style}
      >
        <TextMinimum style={{ color: theme.inputFocusBorder }}>
          {number > 0 ? `${number}` : ""}
        </TextMinimum>
      </CircleContainer>
    </>
  );
};

export default Circle;
