import { InputFieldProps } from "../components/Input";

export const formSubscription: InputFieldProps[] = [
  {
    placeholder: "E-mail",
    type: "email",
    name: "email",
  },
  {
    placeholder: "Frequência",
    type: "select",
    name: "frequency", // Nome do campo que será enviado no formulário
    options: [
      { value: "week", label: "Semanal" },
      { value: "month", label: "Mensal" },
      { value: "minute", label: "Minuto" },
    ],
  },
];
