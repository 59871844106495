import React, { useState, useEffect } from "react";
import Button from ".";
import useRemoteConfigValue from "../../hooks/remoteConfig";
import { animated, useSpring } from "@react-spring/web";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPricesProduct } from "../../models/ManagerProducts";
import Modal from "react-modal";
import { InputFieldIndependent } from "../Input/InputIndependent";
import { getGroupByProduct } from "../../models/ManagerIntegrations";
import { toast } from "react-toastify";
import { createPix, verifyPaymentStatus } from "../../models/payments";
import { validarEmail } from "../../utils/validateEmail";
import { formatCurrency } from "../../utils/formatCurrency";

interface Price {
  id: string;
  interval: "month" | "week" | "day";
  priceId: string;
  productId: string;
  status: "enabled" | "disabled";
  typePayment: "pix" | "stripe";
  unitAmount: string;
  currency: "BRL" | "USD" | "EUR";
}

export const getTranslatePortugues = (interval: string) => {
  switch (interval) {
    case "month":
      return "Assinar por Mês";
    case "week":
      return "Assinar por Semana";
    case "annual":
      return "Ano";
    case "day":
      return "Assinar por Dia";
    default:
      return "";
  }
};
const CheckoutButton: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [prices, setPrices] = useState<Price[]>([]);
  const isActiveForBuy = useRemoteConfigValue("isActiveForBuy");
  const { nickname } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataPix, setDataPix] = useState<Price | null>(null);
  const [email, setEmail] = useState<string>("");
  const [codPix, setCodPix] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [copied, setCopied] = useState(false);
  const [showInfor, setShowInfor] = useState(false);
  const [errorPayment, setErrorPayment] = useState(false);
  const location = useLocation();
  // Extract the groupId from the query string
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("id");

  const animationProps = useSpring({
    from: { scale: 0 },
    to: async (next) => {
      while (true) {
        await next({ scale: 1.02 });
        await next({ scale: 1 });
      }
    },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getPricesProduct({ productId });
        if (isActiveForBuy) {
          setPrices(response?.data);
        }

        setLoading(false);
      } catch (error) {
        console.log("Erro ao buscar preços:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [isActiveForBuy, nickname, productId]);

  const getGroupIdByProductID = async ({
    typePayment,
    productId,
  }: {
    typePayment: string;
    productId: string;
  }) => {
    try {
      const data = await getGroupByProduct({
        productId,
        typePayment: typePayment,
      });
      return data;
    } catch (error) {
      return null;
    }
  };
  const handleClick = async (priceId: string, typePayment = "stripe") => {
    setLoading(true);

    const data = await getGroupIdByProductID({
      productId: productId || "",
      typePayment,
    });

    // Chame seu endpoint do servidor para criar a sessão de checkout
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/createCheckoutStripe`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nickname,
          priceId: priceId,
          successUrl: `${process.env.REACT_APP_FRONT_URL}/page/successPayment?productId=${productId}`, // URL de sucesso
          cancelUrl: `${process.env.REACT_APP_FRONT_URL}/page/${nickname}?id=${data?.id}`, // URL de cancelamento
        }),
      }
    );
    const { url } = await response.json();
    // Redirecione o usuário para o checkout usando o ID da sessão
    window.location.href = url;
  };
  const handleGeneratePixCode = async (
    event: any,
    frequency: "week" | "month" | "day",
    typePayment: "pix" | "stripe"
  ) => {
    event.preventDefault();
    console.log("gerando pixx...");
    try {
      setLoading(true);
      const data = await getGroupIdByProductID({
        productId: productId || "",
        typePayment,
      });
      const response = await createPix({
        email: email,
        nickname: nickname || "",
        groupId: data?.groupId,
        frequency: frequency || "week",
      });
      setCodPix(response?.data?.qrCode);
      setPaymentId(response?.data?.paymentId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        setShowInfor(true);
        toast.success(`Código copiado!`);
      })
      .catch((err) => {
        setCopied(false);
        console.error("Erro ao copiar o texto: ", err);
      });
  };

  const handleCloseModal = () => {
    setCodPix("");
    setIsModalOpen(false);
  };

  const verifyPaymentPix = async () => {
    setErrorPayment(false);
    setLoading(true);
    try {
      const response = await verifyPaymentStatus({
        email,
        nickname: nickname || "",
        paymentId,
      });
      if (!response) {
        setErrorPayment(true);
      } else {
        navigate(`/page/successPayment?productId=${productId}`);
      }
    } catch (error: any) {
      setErrorPayment(true);
      toast.error(error?.response?.data?.message || "");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showInfor) {
      setTimeout(() => {
        setShowInfor(false);
      }, 5000);
    }
  }, [showInfor]);

  const pixPrices = prices?.filter((price) => price.typePayment === "pix");
  const stripePrices = prices?.filter(
    (price) => price.typePayment === "stripe"
  );

  return (
    <animated.div style={{ zIndex: 10, ...animationProps }}>
      <div>
        {isActiveForBuy && (
          <h1
            style={{
              fontSize: 18,
              margin: 0,
              marginBottom: 24,
              marginTop: 24,
              fontWeight: "800",
            }}
          >
            Assinar com Pix (Sign with PIX)
          </h1>
        )}
        {pixPrices.map((price) => (
          <Button
            loading={loading}
            style={{ marginBottom: 12 }}
            key={price?.id}
            onClick={() => {
              if (price?.id) {
                setDataPix(price);
                setIsModalOpen(true);
              }
            }}
            title={`${getTranslatePortugues(price?.interval)} ${formatCurrency(
              price?.unitAmount,
              price?.currency || "BRL"
            )} ${price?.currency.toUpperCase()}`}
          />
        ))}
      </div>
      <div>
        {isActiveForBuy && (
          <h1
            style={{
              fontSize: 18,
              margin: 0,
              marginBottom: 24,
              marginTop: 38,
              fontWeight: "800",
            }}
          >
            Assinar com outras opções (Subscribe with other options)
          </h1>
        )}
        {stripePrices.map((price) => (
          <Button
            loading={loading}
            style={{ marginBottom: 12 }}
            key={price?.id}
            onClick={() => {
              if (price?.priceId) {
                handleClick(price?.priceId);
              }
            }}
            title={`${getTranslatePortugues(price?.interval)} ${formatCurrency(
              price?.unitAmount,
              price?.currency || "BRL"
            )} ${price?.currency.toUpperCase()}`}
          />
        ))}
      </div>
      {/* {prices?.map((price) => (
        <Button
          loading={loading}
          style={{ marginBottom: 12 }}
          key={price?.id}
          onClick={() => {
            if (price.typePayment === "stripe" && price?.priceId) {
              handleClick(price?.priceId);
            } else if (price.typePayment === "pix" && price?.id) {
              console.log({ price });
              setDataPix(price);
              setIsModalOpen(true);
            }
          }}
          title={`${getTranslatePortugues(price?.interval)} ${formatCurrency(
            price?.unitAmount,
            price?.currency || "BRL"
          )} ${price?.currency.toUpperCase()}`}
        />
      ))} */}
      <Modal
        isOpen={isModalOpen}
        contentLabel="Gerar Código Pix"
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            zIndex: 10000,
            backgroundColor: "rgba(210, 2, 83, 0.5)",
          },
          content: {
            zIndex: 10000,
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            background: "white",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4",
            outline: "none",
            padding: "20px",
            margin: "110px 24px 90px 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          },
        }}
      >
        {codPix ? (
          <div style={{ padding: 20 }}>
            <div>
              <h1
                style={{
                  margin: 0,
                  fontSize: 24,
                  marginBottom: 12,
                  marginTop: 1,
                }}
              >
                Instruções
              </h1>
              <h1 style={{ margin: 0, fontSize: 18, marginBottom: 12 }}>
                1 - Copie o código Pix e realize o pagamento
              </h1>
              {/* <h1
                style={{
                  margin: 0,
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  fontSize: 16,
                  marginBottom: 20,
                  color: "#565656",
                }}
              >
                Cole o código pix no seu app de pagamentos
              </h1> */}
              <h1 style={{ margin: 0, fontSize: 18, marginBottom: 12 }}>
                2 - Após o pagamento você receberá acesso ao link de acesso VIP
              </h1>
              {/* <h1
                style={{
                  margin: 0,
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  fontSize: 16,
                  color: "#565656",
                }}
              >
                Link:
              </h1> */}
            </div>
            <h1
              style={{
                margin: 0,
                overflowWrap: "break-word",
                wordWrap: "break-word",
                flexWrap: "wrap",
                fontSize: 16,
                marginBottom: 20,
                marginTop: 48,
              }}
            >
              {codPix?.substring(0, 30)}...
            </h1>
            <Button
              onClick={() => handleCopyText(codPix)}
              title={"Copiar Código PIX"}
            />
            {copied && showInfor ? (
              <h1
                style={{
                  fontSize: 18,
                  margin: 0,
                  color: "#9da0a3",
                  marginTop: 8,
                }}
              >
                Código PIX Copiado!
              </h1>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 1,
                borderColor: "#fff",
                borderStyle: "solid",
                padding: 12,
                marginTop: 48,
                backgroundColor: copied ? "#fcba07" : "#cccccc",
                borderRadius: 4,
              }}
              onClick={verifyPaymentPix}
            >
              <h1 style={{ fontSize: 18, margin: 0, color: "#fff" }}>
                {loading ? "Verificando..." : "Verificar pagamento"}
              </h1>
            </div>
            {errorPayment && (
              <h1
                style={{ fontSize: 14, margin: 0, color: "#000", marginTop: 8 }}
              >
                ⚠️ Pagamento não realizado, efetue o pagamento para ter acesso
              </h1>
            )}
          </div>
        ) : (
          <>
            <h1
              style={{
                fontSize: 24,
                margin: 0,
                marginBottom: 24,
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              {getTranslatePortugues(dataPix?.interval || "")}
            </h1>
            <h1
              style={{
                fontSize: 24,
                margin: 0,
                marginBottom: 24,
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              {formatCurrency(
                dataPix?.unitAmount ?? "",
                dataPix?.currency ?? "BRL"
              )}
            </h1>
            <div style={{ marginTop: 25 }}>
              <h1
                style={{
                  fontSize: 12,
                  margin: 0,
                  marginBottom: 34,
                  color: "#525252",
                }}
              >
                Digite um e-mail válido para gerar o código de pagamento e após
                o pagamento você receberá o acesso
              </h1>
              <form>
                <InputFieldIndependent
                  placeholder={"E-mail"}
                  name={"email"}
                  type={"text"}
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
                <Button
                  onClick={(event) =>
                    handleGeneratePixCode(
                      event,
                      dataPix?.interval || "week",
                      dataPix?.typePayment || "pix"
                    )
                  }
                  title={"Gerar Código Pix"}
                  loading={loading}
                  disabled={!validarEmail(email)}
                />
              </form>
            </div>
          </>
        )}
      </Modal>
    </animated.div>
  );
};

export default CheckoutButton;
