import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/buttons";
import { useEffect, useState } from "react";
import { getSubscriptions } from "../../models/ManagerSubscriptions";
import { format } from "date-fns";
import { SubContainer } from "../../components/container";
import {
  TextLarge,
  TextMedium,
  TextMinimum,
} from "../../components/texts/TextStyles";
import { useTheme } from "styled-components";

const STATUS = {
  'pendente' : {backgroundColor: 'red', textColor: 'pink'},
  'ativo' : {backgroundColor: 'green', textColor: 'yellow'},
  'expirado' : {backgroundColor: 'blue', textColor: 'orange'},
}

export const ManagerSubscriptions = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [subscriptions, setSubscription] = useState([
    {status: 'pendente'},
    {status: 'ativo'},
    {status: 'expirado'},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
  ]);
  const location = useLocation();
  // Extract the groupId from the query string
  const searchParams = new URLSearchParams(location.search);
  const groupId = searchParams.get("groupId");

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const subscriptionsData = await getSubscriptions({ groupId });
  //       setSubscription(subscriptionsData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);
  return (
    <div
      style={{
        overflow: "auto", // Permite o scroll
        overflowY: "auto", // Scroll apenas vertical
        height: "100vh",
      }}
    >
      <Button
        onClick={() => navigate(`/dashboard/subscriptions/form/${groupId}`)}
        title={"Adicionar novo assinante"}
      />
      <SubContainer style={{ margin: 24 }}>
        <TextLarge style={{ fontWeight: "800", marginBottom: 24 }}>
          Assinantes
        </TextLarge>
        {subscriptions?.map((item: any, index) => {
          //const formattedEndDate = format(item?.endDate, "dd/MM/yyyy HH:mm:ss");
          return (
            <SubContainer
              style={{
                flexDirection: "row",
                display: "flex",
                padding: 24,
                marginBottom: 12,
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-start", // Alinhando ao topo quando houver wrap
              }}
              key={index}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <SubContainer
                  style={{
                    backgroundColor: theme.greenLight,
                    padding: 12,
                  }}
                >
                  <TextMinimum
                    style={{ color: theme.greenText, fontWeight: "800" }}
                  >
                    Ativo
                  </TextMinimum>
                </SubContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center", // Garante que os itens de cada lado estejam alinhados ao topo
                    justifyContent: "space-between",
                    width: "100%",
                    flexWrap: "wrap",
                    marginLeft: 12, // Ajusta o espaçamento entre os blocos
                    gap: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 6,
                      flex: 1,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "400", color: theme.textGray }}
                    >
                      Jose jssjfh
                    </TextMedium>
                    <TextMinimum style={{ color: theme.textGray }}>
                      hallysonjose10@gmail.com
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Status
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      ATIVO
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Assinou em
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      20/09/2024 ás 12:30
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Tipo de Assinatura
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      Mensal
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Valor pago
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      R$500
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Qtd Renovação
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      1
                    </TextMinimum>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      gap: 6,
                    }}
                  >
                    <TextMedium
                      style={{ fontWeight: "800", color: theme.textGray }}
                    >
                      Renovação
                    </TextMedium>
                    <TextMinimum
                      style={{ fontWeight: "bold", color: theme.greenText }}
                    >
                      Faltam 10 dias para renovar
                    </TextMinimum>
                  </div>
                </div>
              </div>
            </SubContainer>
          );
        })}
      </SubContainer>
    </div>
  );
};
