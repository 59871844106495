import styled from "styled-components";

// Estilize o container para ser flexível e responsivo
export const ContainerDashboard = styled.div`
  display: flex;
  flex-wrap: wrap; // Permite que os itens quebrem para a linha seguinte em telas menores
  gap: 16px; // Espaçamento entre os itens (opcional)
  align-items: flex-start; // Alinha os itens verticalmente ao início
  justify-content: flex-start;
  max-width: 100%; // Largura máxima em telas maiores

  @media (max-width: 912px) {
    // Ajuste o ponto de interrupção conforme necessário
    max-width: 100%; // Largura máxima em telas menores
    flex-direction: column;
  }
`;
