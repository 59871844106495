import { InputFieldProps } from "../components/Input";

export const formLogin: InputFieldProps[] = [
  {
    placeholder: "E-mail",
    type: "email",
    name: "email",
  },
  {
    placeholder: "Senha",
    type: "password",
    name: "password",
  },
];
