import api from "../conections/axios";

export type PaymentMethod = "telegram" | "mp" | "stripe";

interface ManagerTokenIntegration {
  accessToken: string;
  platform: PaymentMethod;
}
interface ManagerStripeTokenIntegration {
  secretKey: string;
  publicKey: string;
  platform: PaymentMethod;
}

export const registerTokenManagerIntegrations = async ({
  accessToken,
  platform,
}: ManagerTokenIntegration) => {
  try {
    const response = await api.post(`api/platform/registerToken`, {
      accessToken,
      platform,
    });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const registerTokenStripeManagerIntegrations = async ({
  publicKey,
  secretKey,
  platform,
}: ManagerStripeTokenIntegration) => {
  try {
    const response = await api.post(`api/platform/registerStripeToken`, {
      publicKey,
      secretKey,
      platform,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTokensAndSecrets = async () => {
  try {
    const response = await api.get(`api/platform/getTokens`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupByProduct = async ({
  productId,
  typePayment,
}: {
  productId: string;
  typePayment: string;
}) => {
  try {
    const response = await api.get(
      `api/getGroupByProduct?productId=${productId}&typePayment=${typePayment}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
