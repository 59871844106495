export const IconComponent = ({
  color,
  width = 20,
  height = 20,
  Component,
}: {
  color?: string;
  width?: number;
  height?: number;
  Component: React.ElementType;
}) => {
  return (
    <Component
      style={{ fill: color || "#D20253", display: "inline-block" }}
      width={width}
      height={height}
    />
  );
};
