import { InputFieldProps } from "../components/Input";

export const formSignUp: InputFieldProps[] = [
  {
    placeholder: "E-mail",
    type: "email",
    name: "email",
  },
  {
    placeholder: "Senha",
    type: "password",
    name: "password",
  },
  {
    placeholder: "Apelido",
    type: "text",
    name: "nickname",
  },
  {
    placeholder: "WhatsApp",
    type: "tel",
    name: "whatsapp",
  },
  // {
  //   placeholder: "Pix",
  //   type: "text",
  //   name: "pix",
  // },
];
