import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGeneral } from "../../../components/form/formGeneral";
import { useState } from "react";
import { createPrice } from "../../../models/ManagerProducts";
import { formPrice } from "../../../forms/prices";
import { priceSchema } from "../../../shemas/price";

interface FormPriceInterface {
  price: number;
  currency: "BRL" | "EUR" | "USD";
  frequency: "week" | "month" | "minute";
  typePayment: "pix" | "stripe";
}

const FormPrice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { productId } = useParams();
  const handleCreatePrice = async (values: FormPriceInterface) => {
    setLoading(true);
    try {
      const response = await createPrice({
        productId: productId || "",
        unitAmount: values.price,
        interval: values.frequency,
        typePayment: values.typePayment,
        currency: values.currency,
      });
      toast.success("Preço salvo com sucesso");
      navigate(-1);
    } catch (error) {
      toast.error("Erro ao salvar preço, contacte o suporte!");
    }
  };

  return (
    <div className="container">
      <div className="sub-container ">
        <FormGeneral
          initialValues={{}}
          Shema={priceSchema}
          form={formPrice}
          titleButtonAction="Cadastrar"
          title={"Cadastre um novo Preço"}
          loading={loading}
          onSubmit={handleCreatePrice}
        />
      </div>
    </div>
  );
};

export default FormPrice;
