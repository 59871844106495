import api from "../conections/axios";
import { SignInTelegramInfor } from "../types/telegram-signin";
import { UserType } from "../types/user";

export const userApi = {
  // async authCallback(data: SignInTelegramInfor) {
  //   try {
  //     const response = await api.post(`/auth-callback`, data);
  //     return response.data;
  //   } catch (error) {
  //     console.error("Erro no authCallback:", error);
  //     throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
  //   }
  // },
  async getUser(): Promise<UserType> {
    try {
      const response = await api.get(`/auth/user`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
