import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { auth, firebase } from "../firebase";
import api from "../conections/axios";
import { userApi } from "../services/user";
import { UserType } from "../types/user";

interface AuthContextType {
  user: firebase.User | null;
  userInfor: UserType | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  getUser: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userInfor, setUserInfor] = useState<UserType | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        localStorage.setItem("idToken", idToken);
        setUser(user);
      } else {
        localStorage.removeItem("idToken");
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (
    email: string,
    password: string
  ): Promise<void | any> => {
    try {
      const userCredential = await auth.signInWithEmailAndPassword(
        email,
        password
      );
      const idToken = (await userCredential.user?.getIdToken()) || "";
      const response = await api.post("/auth/login", { idToken });
      return response;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      return null;
    }
  };

  const getUser = async () => {
    try {
      const response = await userApi.getUser();
      return setUserInfor(response);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async (): Promise<void> => {
    await auth.signOut();
    localStorage.removeItem("idToken");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, userInfor, login, logout, getUser }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
