import { useNavigate } from "react-router-dom";
import { OptionDashBord } from "../optionDashBoard";
import { ContainerDashboard } from "./styledComponents";
import { ReactComponent as ManagerGroups } from "../../assets/managerGroups.svg";
import { ReactComponent as PackageMenu } from "../../assets/svg/packageMenu.svg";
import { auth } from "../../firebase";

export const HomeDashboard = () => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  return (
    <ContainerDashboard>
      <OptionDashBord
        title={"Grupo VIP e Assinantes"}
        subTitle={"Gerenciamento de grupos vip e assinantes"}
        qtdCount={0}
        onPress={() =>
          navigate(`/groups/${currentUser?.displayName}`)
        }
        Component={ManagerGroups}
      />
      <OptionDashBord
        title={"Packs"}
        subTitle={"Crie packs e veja qual vende mais"}
        qtdCount={0}
        onPress={() => navigate(`/packs/${currentUser?.displayName}`)}
        Component={PackageMenu}
      />
    </ContainerDashboard>
  );
};
