import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import { InputMask, useMask } from "@react-input/mask";
import { InputWrapper, Placeholder, StyledInput } from "./styledComponents";

export interface InputFieldProps {
  options?: any;
  placeholder: string;
  name: string;
  type: string;
  as?: any;
  className?: string;
  mask?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputMaskField: React.FC<InputFieldProps> = ({
  placeholder,
  name,
  type,
  mask,
  as,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useMask({
    mask: "(__) _____-____",
    replacement: { _: /\d/ },
  });
  return (
    <InputWrapper>
      <Field name={name}>
        {({ field }: FieldProps<string>) => (
          <>
            <StyledInput
              {...field}
              {...props}
              type={type}
              onFocus={() => setIsFocused(true)}
              ref={inputRef}
              onBlur={(e) => {
                setIsFocused(false);
                field.onBlur(e);
              }}
              isFocused={isFocused}
              hasValue={!!field.value}
            />
            <Placeholder hasValue={!!field.value} isFocused={isFocused}>
              {placeholder}
            </Placeholder>
          </>
        )}
      </Field>
    </InputWrapper>
  );
};
