import { InputFieldProps } from "../components/Input";

export const formProduct: InputFieldProps[] = [
  {
    placeholder: "Título",
    type: "text",
    name: "title",
  },
  {
    placeholder: "Descrição",
    type: "text",
    name: "description",
  },
];
