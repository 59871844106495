import styled from "styled-components";
import { ThemeProps } from "../../themes/theme";

export const ContainerParent = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100%;
  width: 100%;
  background-color: ${(props) =>
    props.theme.containerBackground}; /* Aplica a cor do tema */
  padding-bottom: 24px;
  padding-top: 24px;
`;
