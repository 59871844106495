import api from "../conections/axios";
import { TelegramGroups } from "../types/telegram-groups";

export const groupApi = {
  async getGroups(): Promise<TelegramGroups[]> {
    try {
      const response = await api.get(`/grupos`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
