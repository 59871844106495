import { getValue, remoteConfig, fetchAndActivate } from "..";

const fetchAndActivateConfig = async () => {
  try {
    // Recupere e ative as configurações remotas
    await fetchAndActivate(remoteConfig);
    console.log("Configurações remotas recuperadas e ativadas com sucesso.");
  } catch (error) {
    console.error(
      "Erro ao recuperar e ativar as configurações remotas:",
      error
    );
  }
};
const getValueConfig = async (value: string) => {
  await fetchAndActivateConfig();
  return await getValue(remoteConfig, value);
};

export { getValueConfig };
