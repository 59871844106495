import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getPricesProduct } from "../../models/ManagerProducts";
import Button from "../../components/buttons";
import { formatCurrency } from "../../utils/formatCurrency";
import { getTranslatePortugues } from "../../components/buttons/CheckoutButton";

interface Price {
  currency: "BRL" | "USD" | "EUR";
  id: string;
  interval: "month" | "week";
  priceId: string;
  productId: string;
  status: "enabled" | "disabled";
  typePayment: "stripe" | "pix";
  unitAmount: string;
}

export const ManagerPrices = () => {
  const navigate = useNavigate();
  //const { groupId } = useParams();
  const [prices, setPrices] = useState<Price[]>([]);
  const location = useLocation();
  // Extract the groupId from the query string
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");

  useEffect(() => {
    (async () => {
      try {
        const response = await getPricesProduct({
          productId,
        });
        setPrices(response.data);
      } catch (error) {
        //toast.error("Erro ao buscar preços");
      }
    })();
  }, [productId]);
  return (
    <div className="container-manager">
      <h1>Preços</h1>
      <Button
        onClick={() =>
          navigate(`/dashboard/products/prices/createPrice/${productId}`)
        }
        title="Cadastrar novo preço"
      />
      {prices?.map((item, index) => (
        <div key={index} className="card">
          <span>{item?.id}</span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label>
              {formatCurrency(item.unitAmount, item?.currency || "BRL")}
            </label>
            <span>{item?.currency}</span>
          </div>
          <label>{item?.typePayment}</label>
          <label>{getTranslatePortugues(item?.interval)}</label>
        </div>
      ))}
    </div>
  );
};
//#f08793