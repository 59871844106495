import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputField, InputFieldProps } from "../../Input";
import Button from "../../buttons";
import "./integrations.css";
import { InputMaskField } from "../../Input/inputMask";

interface FormGeneralProps {
  form: InputFieldProps[];
  title: string;
  titleButtonAction: string;
  Shema?: Yup.AnySchema | any;
  loading: boolean;
  initialValues: Record<string, string>;
  onSubmit: (values: any) => void;
}

export const FormGeneral: React.FC<FormGeneralProps> = ({
  form,
  title,
  titleButtonAction,
  Shema,
  loading,
  initialValues,
  onSubmit,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <h1>{title}</h1> */}
      <Formik
        initialValues={initialValues}
        validationSchema={Shema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, isValid, setFieldValue, values }) => {
          return (
            <Form className="conteiner-form">
              {form.map((item: any) => {
                return (
                  <div
                    key={item.name}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.type === "select" && item.options ? (
                      <div className="select-container">
                        <span>{item.placeholder}</span>
                        <select
                          id={item.name}
                          name={item.name}
                          value={values[item.name] || ""}
                          onChange={(e) => {
                            setFieldValue(item.name, e.target.value);
                          }}
                        >
                          {item.options.map((option: any, index: any) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : item.type === "tel" ? (
                      <InputMaskField
                        key={item.name}
                        placeholder={item.placeholder}
                        type={item.type}
                        name={item.name}
                        mask={true}
                      />
                    ) : (
                      <InputField
                        key={item.name}
                        placeholder={item.placeholder}
                        type={item.type}
                        name={item.name}
                      />
                    )}
                    {/* {errors[item.name] && (
                  <span>{errors[item.name]}</span>
                )} */}
                  </div>
                );
              })}
              <Button
                type="submit"
                title={titleButtonAction}
                disabled={!isValid}
                loading={loading}
                style={{ marginTop: 24 }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
