import { useState } from "react";
import { useQueryClient } from "react-query";
import "./optionsGroups.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface DataType {
  title: string;
  groupChatId: number;
  adminNickname: string;
  botChatId: number;
  createdAt: Date;
}

export const OptionsGroup = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();

  // Função para pegar o valor do parâmetro 'bot' na URL
  const getBotParam = () => {
    const params = new URLSearchParams(location.search);
    return params.get("bot");
  };

  const botParam = getBotParam();

  const [options, setOptions] = useState([
    {
      id: 0,
      title: "Produtos",
      link: "/dashboard/products",
    },
    {
      id: 1,
      title: "Assinantes",
      link: "/dashboard/subscriptions",
    },
  ]);

  return (
    <div className="container-manager">
      <h1>Acesse</h1>
      {options?.map((item) => (
        <div
          key={item?.id}
          onClick={() =>
            navigate(`${item.link}?groupId=${groupId}&bot=${botParam}`)
          }
          className="card"
        >
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};
