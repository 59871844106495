import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { InputField } from "../../Input";
import { SubContainer } from "../../container";
import { TextLarge, TextMinimum } from "../../texts/TextStyles";
import { PaymentMethod } from "../../../models/ManagerIntegrations";
import { ReactComponent as CheckedIcon } from "../../../assets/svg/checked.svg";
import { useTheme } from "styled-components";

export const IntegrationsForm = ({
  src,
  title,
  subTitle,
  placeholder,
  name,
  type,
  linkInfor,
  value,
  onChange,
  Component,
  DescriptionComponent,
  isChecked
}: {
  src: any;
  title: string;
  subTitle: string;
  placeholder: string;
  name: PaymentMethod;
  value: string;
  type: string;
  linkInfor?: string;
  onChange?: (e: any) => void;
  Component?: React.ReactNode;
  isChecked?: boolean;
  DescriptionComponent?: React.ReactNode;
}) => {
  // Estado para armazenar o valor anterior
  const [prevValue, setPrevValue] = useState(value);
  const theme = useTheme();

  useEffect(() => {
    setPrevValue(value); // Atualiza o valor anterior quando o valor inicial muda
  }, [value]);

  return (
    <Formik
      initialValues={{ [name]: value }} // Inicializando o valor do campo
      enableReinitialize
      onSubmit={(values) => {
        // Chama o onChange somente se necessário
        if (onChange) {
          onChange(values);
        }
      }}
    >
      {({ handleBlur, handleChange, values, submitForm }) => {
        const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;

          // Verifica se o novo valor é diferente do valor anterior e se não está vazio
          if (newValue !== prevValue && newValue.trim() !== "") {
            if (onChange) {
              onChange(values);
            }
          }

          // Atualiza o valor anterior
          setPrevValue(newValue);

          // Chama o handleChange do Formik
          handleChange(e);
        };

        return (
          <SubContainer style={{ flex: 1, width: "100%" }}>
            <div
              style={{
                marginBottom: 24,
                gap: 8,
                display: "flex",
                alignItems: "center",
              }}
            >
              {src ? (
                <img
                  src={src}
                  alt={`icone ${title}`}
                  style={{ width: 48, height: 48 }}
                />
              ) : null}
              <div style={{ gap: 4, display: "flex", flexDirection: "column" }}>
                <TextLarge>
                  {title}
                  {" "}
                  {
                    isChecked && 
                      <CheckedIcon
                      height={18}
                      width={18}
                      style={{ fill: theme.greenText }}
                    />
                  }
                </TextLarge>
                <TextMinimum>{subTitle}</TextMinimum>
              </div>
            </div>
            {!Component ? (
              <Form>
                <Field
                  name={name}
                  placeholder={placeholder}
                  type={type}
                  as={InputField}
                  onBlur={handleFieldChange}
                  //onChange={handleFieldChange} // Usando a nova função de mudança
                  value={values[name]}
                />
                {linkInfor ? (
                  <a
                    style={{ alignSelf: "center", marginTop: 24 }}
                    href={linkInfor}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Saiba mais
                  </a>
                ) : null}
              </Form>
            ) : (
              Component
            )}
            <div>{DescriptionComponent && DescriptionComponent}</div>
          </SubContainer>
        );
      }}
    </Formik>
  );
};
