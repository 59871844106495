import api from "../conections/axios";

export const registerSubscription = async ({
  email,
  frequency,
  groupId,
}: {
  groupId: string;
  email: string;
  frequency: string;
}) => {
  try {
    const response = await api.post("api/bot-manager/subscriptionBot", {
      groupId,
      frequency,
      status: "active",
      typePayment: "pix",
      subscriber: {
        email,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getSubscriptions = async ({ groupId }: { groupId: any }) => {
  try {
    const response = await api.get(
      `api/bot-manager/getSubscriptions/${groupId}`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
