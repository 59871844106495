import { SubContainer } from "../container";
import { SlArrowRight } from "react-icons/sl";
import { TextMedium, TextMinimumMedium } from "../texts/TextStyles";
import { useTheme } from "styled-components";
import Circle from "../circleCount";
import { IconComponent } from "../icon/icon";
import { ContainerButtonNext } from "../container/ContainerButtonNext";

interface OptionDashboard {
  title: string;
  subTitle: string;
  qtdCount?: number;
  onPress: () => void;
  style?: any;
  Component?: React.ElementType;
}
export const OptionDashBord = ({
  title,
  subTitle,
  qtdCount,
  onPress,
  Component,
  style
}: OptionDashboard) => {
  const theme = useTheme();

  return (
    <SubContainer
      style={{ cursor: "pointer", flex: 1, width: "100%",...style }}
      onClick={onPress}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <TextMedium
            style={{
              fontWeight: "800",
              color: theme.blackDefault,
              letterSpacing: 0,
            }}
          >
            {title}
          </TextMedium>
          <TextMinimumMedium
            style={{
              fontWeight: "400",
              color: theme.textGray,
              marginTop: 2,
              letterSpacing: 1,
            }}
          >
            {subTitle}
          </TextMinimumMedium>
        </div>
        <ContainerButtonNext>
          <SlArrowRight color={theme.blackDefault} />
        </ContainerButtonNext>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: 24,
          gap: 8,
        }}
      >
        {Component ? (
          <IconComponent Component={Component} color={theme.inputFocusBorder} />
        ) : null}
        {qtdCount ? <Circle number={qtdCount} /> : null}
      </div>
    </SubContainer>
  );
};
