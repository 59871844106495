import api from "../conections/axios";

export const botApi = {
  async checkStatus() {
    try {
      const response = await api.get(`/telegram/webhook-status`);
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
  async startBot() {
    try {
      const response = await api.post(`/telegram/start-bot`);
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
  async stopBot() {
    try {
      const response = await api.post(`/telegram/stop-bot`);
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
};
