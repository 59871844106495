import { fetchData } from ".";
import { RegisterType } from "./login";

const cadastrarUsuario = async (dadosUsuario: RegisterType) => {
  try {
    const response = await fetchData("registro", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dadosUsuario),
    });
    // Se a resposta estiver OK, você pode lidar com a resposta aqui

    console.log("Usuário cadastrado com sucesso:", response);
    return response;
  } catch (error: any) {
    console.error("Erro ao cadastrar usuário:", error);
    throw error;
  }
};

export { cadastrarUsuario };
