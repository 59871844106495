import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Page from "../../components/form/checkout";
import "./checkout.css"; // Importe o arquivo CSS
import HeaderCheckout from "./HeaderCheckout";

export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const LandPage: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
     <HeaderCheckout />
      <div className="checkout-container ">
        <Page />
      </div>
    </Elements>
  );
};

export default LandPage;
