// src/components/styledComponents.ts
import styled from "styled-components";

// Container estilizado
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: ${(props) => props.theme.buttonBackground};
  padding: 12px;
  border-radius: 12px;
  gap: 8px;
  flex: 1;
  max-width: 80%;
  min-width: 80%;
  align-self: center;
  margin-bottom: 3rem;
`;

// Estilo do botão
interface ButtonProps {
  isSelected: boolean;
}

export const Button = styled.button<ButtonProps>`
  padding: 12px 24px;
  border: none;
  flex: 1;
  font-size: 16px;
  font-weight: 800;
  color: ${(props) =>
    props.isSelected ? props.theme.buttonSelectedText : props.theme.buttonText};
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.buttonSelectedBackground
      : props.theme.buttonBackground};
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.buttonHoverBackground};
    color: ${(props) => props.theme.buttonHoverText};
  }
`;
