import * as yup from "yup";

// Schema de validação usando yup
export const priceSchema = yup.object().shape({
  price: yup
    .number()
    .required("Preço é obrigatório")
    .typeError("Preço deve ser um número"),
  frequency: yup
    .string()
    .required("Frequência é obrigatória")
    .oneOf(["week", "month", "minute"], "Frequência inválida"),
  typePayment: yup
    .string()
    .required("Forma de pagamento é obrigatória")
    .oneOf(["pix", "stripe"], "Forma de pagamento inválida"),
  currency: yup
    .string()
    .required("Moeda é obrigatória")
    .oneOf(["BRL", "EUR", "USD"], "Moeda inválida"),
});

