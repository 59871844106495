import { useNavigate } from "react-router-dom";
import { LoginType, RegisterType } from "../../models/login";
import { toast } from "react-toastify";
import { FormGeneral } from "../../components/form/formGeneral";
import { formLogin } from "../../forms/signIn";
import { SignInSchema, SignUpSchema } from "../../shemas/SignIn";
import { useState } from "react";
import { SubContainer } from "../../components/container";
import { ContainerParent } from "../../components/container/ContainerParent";
import ButtonSelector from "../../components/buttons/SelectButton";
import { formSignUp } from "../../forms/signUp";
import { cadastrarUsuario } from "../../models/register";
import { ResponsiveContainer } from "./styledComponents";
import { useAuth } from "../../context/AuthContext";

const SignUp = () => {
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const [selectedOption, setSelectedOption] = useState<"entrar" | "cadastro">(
    "entrar"
  );
  const [loading, setLoading] = useState(false);
  const handleLogin = async (values: LoginType, event?: any) => {
    setLoading(true);
    // Lógica de autenticação aqui
    try {
      event?.preventDefault();
      const res: any = await login(values.email, values.password);
      if (res?.status === 200) {
        navigate(`/dashboard`);
      }
    } catch (error: any) {
      if (error?.message?.includes("auth/invalid-credential")) {
        toast.error("Credenciais inválidas!");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleRegister = async (values: RegisterType) => {
    try {
      setLoading(true);
      const response = await cadastrarUsuario(values);
      if (response) {
        setSelectedOption("entrar");
        return toast.success("Cadastrado com sucesso!");
      }
    } catch (error: any) {
      console.log({ error: error?.message });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ContainerParent
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ResponsiveContainer>
        <SubContainer style={{ flex: 1, width: "100%" }}>
          <ButtonSelector
            selected={selectedOption}
            setSelected={setSelectedOption}
          />
          <FormGeneral
            initialValues={{}}
            Shema={selectedOption === "entrar" ? SignInSchema : SignUpSchema}
            form={selectedOption === "entrar" ? formLogin : formSignUp}
            titleButtonAction={
              selectedOption === "entrar" ? "Entrar" : "Cadastrar"
            }
            title={""}
            loading={loading}
            onSubmit={(values) => {
              if (selectedOption === "entrar") {
                handleLogin({
                  email: values?.email,
                  password: values?.password,
                });
              } else {
                // Remove todos os caracteres não numéricos da string de telefone
                const cleanedPhoneNumber = values?.whatsapp?.replace(/\D/g, "");
                handleRegister({
                  ...values,
                  whatsapp: cleanedPhoneNumber,
                });
              }
            }}
          />
        </SubContainer>
      </ResponsiveContainer>
    </ContainerParent>
  );
};

export default SignUp;
