import api from "../conections/axios";
import { Platform } from "../types/integration";
import { AuthTelegramInforResponse, SignInTelegramInfor } from "../types/telegram-signin";

export const integrationApi = {
  async saveToken(platform: Platform, token: string) {
    try {
      const response = await api.post(`/integration/token-platform`, {
        token,
        platform,
      });
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
  async getAllTokens(platform: Platform[]) {
    try {
      const response = await api.get(
        `/integration/get-all-tokens?data=${platform}`
      );
      return response.data;
    } catch (error) {
      console.error("Erro no authCallback:", error);
      throw new Error("Falha ao autenticar o usuário."); // Mensagem de erro padronizada
    }
  },
  async getInforTelegram(botToken: string): Promise<AuthTelegramInforResponse> {
    try {
      const response = await api.get("/telegram/bot-infor", { params: { botToken } });
      return response.data;
    } catch (error) {
      throw new Error("Falha ao buscar Informações do telegram"); // Mensagem de erro padronizada
    }
  },
  async updateInforUserTelegram(props: SignInTelegramInfor) {
    try {
      const response = await api.post(`/telegram/auth-callback`, { ...props });
      return response.data;
    } catch (error: any) {
      throw new Error("Erro ao tentar atualizar dados do usuário"); // Mensagem de erro padronizada
    }
  },
};
