import React from "react";
import { StyledButton } from "./styledComponents";
import Loader from "../lottie/loading";

type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  loading?: boolean;
  disabled?: boolean;
};

const Button: React.FC<ButtonType> = ({
  title,
  loading,
  disabled,
  ...props
}) => {
  return (
    <StyledButton {...props} disabled={disabled}>
      {loading && <Loader />}
      <span>{title}</span>
    </StyledButton>
  );
};

export default Button;
