import React, { createContext, useState, useContext, ReactNode } from "react";
import { botApi } from "../services/bot";
import { toast } from "react-toastify";

// Define o tipo para o contexto
interface BotContextType {
  isActive: boolean;
  startBot: () => void;
  stopBot: () => void;
  checkStatus: () => void;
}

// Cria o contexto com valores padrão
const BotContext = createContext<BotContextType | undefined>(undefined);

// Define o provedor do contexto
export const BotProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isActive, setIsStatus] = useState(false);

  // Função para obter os tokens e segredos
  const checkStatus = async () => {
    try {
      const response = await botApi.checkStatus();
      setIsStatus(response.active);
    } catch (error) {
      console.log(error);
      //toast.error("Erro ao buscar status do Bot!");
    }
  };

  const startBot = async () => {
    try {
      await botApi.startBot();
      setIsStatus(true);
    } catch (error) {
      setIsStatus(false);
      toast.error("Ocorreu um erro ao tentar iniciar o Bot!");
    }
  };

  const stopBot = async () => {
    try {
      await botApi.stopBot();
      setIsStatus(false);
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar iniciar o Bot!");
    }
  };

  return (
    <BotContext.Provider value={{ isActive, checkStatus, startBot, stopBot }}>
      {children}
    </BotContext.Provider>
  );
};

// Hook personalizado para usar o contexto
export const useBotContext = () => {
  const context = useContext(BotContext);
  if (context === undefined) {
    throw new Error("useBotContext within an AuthProvider");
  }
  return context;
};
