import styled from "styled-components";

// Estilização do Container
export const Container = styled.div`
  display: flex;
  z-index: 2;
  height: 100%;
  // Media query para telas pequenas
  @media (max-width: 768px) {
    position: fixed;
    overflow-x: hidden; // Oculta rolagem horizontal
  }
`;

// Sidebar
export const Sidebar = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? "190px" : "60px")};
  background-color: ${(props) => props.theme.containerMenuBackground};
  color: white;
  transition: width 0.3s ease;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  height: 100%; // Corrigido de "heigth" para "height"
  border-top-right-radius: 12px;
  @media (max-width: 768px) {
    width: ${(props) =>
      props.isOpen ? "190px" : "0"}; // Oculta o sidebar em telas menores
  }
`;

// Sidebar Content
export const SidebarContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  min-width: 100%;
  align-items: center;
  justify-content: space-between; // Garante que o conteúdo principal fique no topo e o item adicional na parte inferior
`;

// Menu Item
export const MenuItem = styled.div<{ isOpen: boolean }>`
  margin: 16px 0;
  cursor: pointer;
  font-size: ${(props) => (props.isOpen ? "18px" : "16px")};
  color: white;
  width: 100%;
  align-self: center;
  max-width: 100%;
`;

// Botão de Toggle
export const ToggleButton = styled.button<{ isOpen: boolean }>`
  position: fixed;
  bottom: 24px;
  left: ${(props) => (props.isOpen ? "190px" : "60px")};
  background-color: ${(props) => props.theme.containerMenuBackground};
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 2000;

  @media (max-width: 768px) {
    left: ${(props) =>
      props.isOpen
        ? "190px"
        : "10px"}; // Ajusta a posição do botão em telas menores
  }
`;

// Overlay
export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    "rgba(210, 2, 83, 0.75)"}; // Cor de fundo semi-transparente
  backdrop-filter: blur(1.5px); // Efeito de blur
  display: ${(props) =>
    props.isOpen ? "block" : "none"}; // Mostrar/ocultar baseado no estado
  z-index: 500; // Certifique-se de que o overlay esteja acima do conteúdo

  @media (max-width: 768px) {
    display: ${(props) =>
      props.isOpen ? "block" : "none"}; // Oculta o overlay em telas menores
  }
`;
