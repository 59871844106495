const baseUrl = process.env.REACT_APP_API_URL;
const fetchData = async (endpoint: string, options = {}) => {
  try {
    const url = `${baseUrl}/${endpoint}`;
    const response = await fetch(url, options);
    if (!response.ok) {
      // Se a resposta não estiver ok, lança um erro com a resposta completa para processamento posterior
      throw response;
    }
    const data = await response.json();
    return data;
  } catch (error: any) {
    if (
      error instanceof Response &&
      error.headers.get("content-type")?.includes("application/json")
    ) {
      // Se o erro for uma resposta da API com conteúdo JSON
      const errorMessage = await error.json();
      if (errorMessage.error) {
        // Se houver uma propriedade "error" no objeto JSON, retorna o valor dessa propriedade
        throw new Error(errorMessage.error);
      } else {
        // Se não houver uma propriedade "error" no objeto JSON, retorna a resposta completa
        throw new Error(JSON.stringify(errorMessage));
      }
    } else {
      // Se ocorrer um erro durante a solicitação ou o processamento da resposta, lança o erro como está
      throw error;
    }
  }
};

export { baseUrl, fetchData };
