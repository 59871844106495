import Button from "..";

const REACT_APP_CLIENT_ID = 7919422949805399;
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const MercadoPagoButton = ({ uid }: { uid: string }) => {
  const handleOAuthMp = () => {
    window.location.href = `https://auth.mercadopago.com/authorization?client_id=${REACT_APP_CLIENT_ID}&response_type=code&platform_id=mp&state=${uid}&redirect_uri=${REACT_APP_API_URL}/integration/auth-mp`;
  };
  return (
    <Button
      style={{ backgroundColor: "#04A9EC" }}
      title="Conectar Mercado Pago"
      onClick={handleOAuthMp}
    />
  );
};
