import api from "../conections/axios";

export const createPix = async ({
  email,
  nickname,
  frequency,
  groupId,
}: {
  email: string;
  nickname: string;
  frequency: "week" | "month" | "day";
  groupId: string;
}) => {
  try {
    const response = await api.post(`api/generatePaymentPix`, {
      email: email,
      nicknameAdmin: nickname,
      frequency: frequency,
      groupId: groupId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyPaymentStatus = async ({
  email,
  nickname,
  paymentId,
}: {
  email: string;
  nickname: string;
  paymentId: string;
}) => {
  try {
    const response = await api.get(
      `api/checkPaymentPendingPix?email=${email}&nickname=${nickname}&paymentId=${paymentId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getProduct = async ({ productId }: { productId: string }) => {
  try {
    const response = await api.get(
      `api/getGroupByProduct?productId=${productId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
