import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getValue,
  getRemoteConfig,
  fetchAndActivate,
  RemoteConfigSettings,
} from "firebase/remote-config";

// Importa o módulo "getAuth" do Firebase Admin SDK
//import auth from "firebase/auth";
import { getStorage } from "firebase/storage";

const getFirebaseConfig = () => {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
};
// Initialize Firebase
const app = firebase.initializeApp(getFirebaseConfig());
const auth = firebase.auth();
const storage = getStorage(app);
const analitycs = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
// Configura o tempo de expiração do cache
const remoteConfigSettings: RemoteConfigSettings = {
  minimumFetchIntervalMillis: 60 * 1000, // Converte para milissegundos
  fetchTimeoutMillis: 60000 // Define o tempo limite de busca em milissegundos (opcional)
};
// Aplica as configurações ao Remote Config
remoteConfig.settings = remoteConfigSettings;
// Exporta o objeto "admin" e a instância do objeto "auth"
export {
  auth,
  storage,
  analitycs,
  remoteConfig,
  fetchAndActivate,
  logEvent,
  getValue,
  firebase,
};
