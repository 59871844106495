import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import { InputWrapper, Placeholder, StyledInput } from "./styledComponents";

export interface InputFieldProps {
  options?: any;
  placeholder: string;
  name: string;
  type: string;
  as?: any;
  className?: string;
  mask?: any;
  value?: string;
  onChange?: any;
  onBlur?: any;
}

export const InputField: React.FC<InputFieldProps> = ({
  placeholder,
  name,
  type,
  as,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputWrapper>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <>
            <StyledInput
              {...field}
              type={type}
              //placeholder={placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              isFocused={isFocused}
              hasValue={!!field.value}
              {...props}
            />
            <Placeholder hasValue={!!field.value} isFocused={isFocused}>
              {placeholder}
            </Placeholder>
          </>
        )}
      </Field>
    </InputWrapper>
  );
};
