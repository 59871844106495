import styled, { css } from "styled-components";

interface ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props) =>
    !props.disabled
      ? props.theme.buttonHoverBackground
      : props.theme.buttonBackground};
  color: ${(props) =>
    !props.disabled ? props.theme.buttonSelectedText : props.theme.buttonText};
  border: none;
  height: 3rem;
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: ${(props) => props.theme.buttonDisabledBackground};
    color: ${(props) => props.theme.buttonDisabledText};
  }
`;
