import { useEffect, useRef } from "react";
import {
  Container,
  MenuItem,
  Sidebar,
  SidebarContent,
  ToggleButton,
  Overlay,
} from "./styledComponents";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg";
import { ReactComponent as DiceMenu } from "../../assets/svg/diceMenu.svg";
import { ReactComponent as LessonMenu } from "../../assets/svg/lessonMenu.svg";
import { ReactComponent as PackageMenu } from "../../assets/svg/packageMenu.svg";
import { ReactComponent as SignoutMenu } from "../../assets/svg/signoutMenu.svg";
import { ReactComponent as IntegrateMenu } from "../../assets/svg/integrateIcon.svg";

//import { ReactComponent as SettingsMenu } from "../../assets/svg/settingsMenu.svg"; // Ícone adicional

import { useTheme } from "styled-components";
import { ContainerButtonNext } from "../container/ContainerButtonNext";
import { TextMinimumMedium } from "../texts/TextStyles";
import { OptionsConfigType } from "../../pages/Dashboard";
import { IconComponent } from "../icon/icon";

interface SidebarMenuProps {
  isOpenMenu: boolean;
  optionsConfig: OptionsConfigType[];
  optionMenuSelected: OptionsConfigType | undefined;
  setIsOpenMenu: (v: boolean) => void;
  logout: () => void;
  selectOption: (v: OptionsConfigType) => void;
}
const iconsMap: { [key: number]: any } = {
  0: DiceMenu, // ícone correspondente ao id 1
  1: PackageMenu, // ícone correspondente ao id 2
  2: IntegrateMenu,
  3: LessonMenu,
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  isOpenMenu,
  optionsConfig,
  optionMenuSelected,
  setIsOpenMenu,
  logout,
  selectOption,
}) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  // Fechar o menu ao clicar fora da área do sidebar
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsOpenMenu(false); // Fecha o sidebar
      }
    };

    if (isOpenMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenMenu]);

  return (
    <Container>
      {/* Overlay para o efeito de blur */}
      <Overlay isOpen={isOpenMenu} />

      {/* Sidebar */}
      <Sidebar ref={sidebarRef} isOpen={isOpenMenu}>
        <SidebarContent isOpen={isOpenMenu}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {optionsConfig.map((item) => {
              return (
                <MenuItem
                  isOpen={isOpenMenu}
                  onClick={(event) => {
                    event.preventDefault();
                    selectOption(item);
                  }}
                >
                  <ContainerButtonNext
                    style={{
                      backgroundColor:
                        optionMenuSelected?.id === item.id
                          ? theme.inputFocusBorder
                          : theme.blackDefault,
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      gap: 16,
                      width: "100%",
                      padding: isOpenMenu ? 24 : 0,
                      justifyContent: isOpenMenu ? "flex-start" : "center",
                    }}
                  >
                    <div>
                      <IconComponent
                        Component={iconsMap[item.id]}
                        color={theme.subContainerBackground}
                      />
                    </div>

                    {isOpenMenu ? (
                      <TextMinimumMedium>{item.title}</TextMinimumMedium>
                    ) : null}
                  </ContainerButtonNext>
                </MenuItem>
              );
            })}
          </div>

          {/* MenuItem Adicional na parte inferior */}
          <MenuItem
            style={{ marginBottom: 38 }}
            isOpen={isOpenMenu}
            onClick={logout}
          >
            <ContainerButtonNext
              style={{ backgroundColor: theme.buttonSelectedBackground }}
            >
              <SignoutMenu
                style={{ fill: theme.subContainerBackground }}
                width={22}
                height={22}
              />
            </ContainerButtonNext>
          </MenuItem>
        </SidebarContent>
      </Sidebar>
      {/* Botão de Toggle */}
      <ToggleButton
        isOpen={isOpenMenu}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
      >
        <MenuIcon
          style={{ fill: theme.subContainerBackground }}
          width={22}
          height={22}
        />
      </ToggleButton>
    </Container>
  );
};

export default SidebarMenu;
