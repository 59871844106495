import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "../buttons";

interface PriceModalProps {
  isOpen: boolean;
  onClose: () => void;
  priceDetails?: {
    priceId: string;
    currency: string;
    interval: string;
    unitAmount: number;
    isActive: boolean;
    groupId: string;
  };
  onSave: (values: typeof initialValues) => void;
}

// Validação com Yup
const validationSchema = Yup.object({
  priceId: Yup.string().required("Price ID é obrigatório"),
  currency: Yup.string().required("Currency é obrigatória"),
  interval: Yup.string().required("Intervalo é obrigatório"),
  unitAmount: Yup.number()
    .min(1, "O valor unitário deve ser maior que zero")
    .required("Unit Amount é obrigatório"),
  isActive: Yup.boolean().required("Status ativo é obrigatório"),
});

// Valores iniciais do formulário
const initialValues = {
  priceId: "",
  currency: "BRL",
  interval: "day",
  unitAmount: 97,
  isActive: true,
  groupId: "-45",
};

const PriceModal = ({
  isOpen,
  onClose,
  priceDetails,
  onSave,
}: PriceModalProps) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Editar Preço</h2>

        <Formik
          initialValues={priceDetails || initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSave(values);
            setSubmitting(false);
            onClose();
          }}
        >
          {({ isSubmitting }) => (
            <StyledForm>
              <FieldWrapper>
                <label>Price ID</label>
                <StyledField type="text" name="priceId" />
                <StyledErrorMessage
                  name="priceId"
                  component="div"
                  className="error"
                />
              </FieldWrapper>

              <FieldWrapper>
                <label>Currency</label>
                <StyledField type="text" name="currency" />
                <StyledErrorMessage
                  name="currency"
                  component="div"
                  className="error"
                />
              </FieldWrapper>

              <FieldWrapper>
                <label>Interval</label>
                <StyledField type="text" name="interval" />
                <StyledErrorMessage
                  name="interval"
                  component="div"
                  className="error"
                />
              </FieldWrapper>

              <FieldWrapper>
                <label>Unit Amount</label>
                <StyledField type="number" name="unitAmount" />
                <StyledErrorMessage
                  name="unitAmount"
                  component="div"
                  className="error"
                />
              </FieldWrapper>

              <FieldWrapper>
                <label>Ativo</label>
                <StyledField type="checkbox" name="isActive" />
                <StyledErrorMessage
                  name="isActive"
                  component="div"
                  className="error"
                />
              </FieldWrapper>

              <Button title="Salvar" type="submit" disabled={isSubmitting} />
              <Button title="Fechar" onClick={onClose} />
            </StyledForm>
          )}
        </Formik>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PriceModal;

// Estilos com styled-components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const FieldWrapper = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
