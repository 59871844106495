import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: white;
    color: #333;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;
