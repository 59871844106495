import { InputFieldProps } from "../components/Input";

export const formPrice: InputFieldProps[] = [
  {
    placeholder: "Preço",
    type: "numeric",
    name: "price",
  },
  {
    placeholder: "Frequência",
    type: "select",
    name: "frequency", // Nome do campo que será enviado no formulário
    options: [
      { value: "week", label: "Semanal" },
      { value: "month", label: "Mensal" },
      { value: "minute", label: "Minuto" },
    ],
  },
  {
    placeholder: "Forma de pagamento",
    type: "select",
    name: "typePayment", // Nome do campo que será enviado no formulário
    options: [
      { value: "pix", label: "PIX" },
      { value: "stripe", label: "Stripe" },
    ],
  },
  {
    placeholder: "Moeda",
    type: "select",
    name: "currency", // Nome do campo que será enviado no formulário
    options: [
      { value: "BRL", label: "Real" },
      { value: "EUR", label: "Euro" },
      { value: "USD", label: "Dólar" },
    ],
  },
];
