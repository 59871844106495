import { useParams } from "react-router-dom";
import { FormGeneral } from "../../components/form/formGeneral";
import { formSubscription } from "../../forms/subscriptions";
import { registerSubscription } from "../../models/ManagerSubscriptions";
import { SubscriptionSchema } from "../../shemas/subcriptions";
import { useState } from "react";
import { toast } from "react-toastify";

export const FormSubscription = () => {
  const { groupId } = useParams();
  const [loading, setLoading] = useState(false);
  const saveSubscription = async ({
    email,
    frequency,
  }: {
    email: string;
    frequency: string;
  }) => {
    setLoading(true);
    try {
      await registerSubscription({
        groupId: groupId || "",
        email,
        frequency,
      });
      toast.success("Assinante cadastrado com sucesso!");
    } catch (error: any) {
      if (error?.response.status === 409) {
        return toast.error(error?.response?.data?.error);
      }

      toast.error("Erro ao tentar cadastrar assinante!");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <FormGeneral
        initialValues={{ frequency: "month" }}
        Shema={SubscriptionSchema}
        form={formSubscription}
        titleButtonAction="Salvar Assinatura"
        title={"Adicionar Assinante"}
        loading={loading}
        onSubmit={(values) =>
          saveSubscription({ email: values.email, frequency: values.frequency })
        }
      />
    </div>
  );
};
