import { SubContainer } from "../container";
import { ContainerButtonNext } from "../container/ContainerButtonNext";
import { TextLarge, TextMedium } from "../texts/TextStyles";
import { ReactComponent as CopyIcon } from "../../assets/svg/copy.svg";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import activeAnimation from "../../assets/active.json";
import { useBotContext } from "../../context/BotContext";
import { useEffect } from "react";

export const Header = ({ username }: { username: string }) => {
  const theme = useTheme();
  const { isActive, checkStatus } = useBotContext();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: activeAnimation, // Passa os dados da animação Lottie
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const toCopy = () => {
    navigator.clipboard
      .writeText(username)
      .then(() => {
        toast.success("Nome de usuário copiado com sucesso!");
      })
      .catch((err) => {
        console.error("Falha ao copiar texto: ", err);
      });
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <SubContainer
      style={{
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: 8,
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextMedium>Usuário</TextMedium>
        <TextLarge style={{ marginLeft: 8, marginRight: 8, fontWeight: "100" }}>
          |
        </TextLarge>
        <TextMedium>{username}</TextMedium>
        <ContainerButtonNext
          onClick={toCopy}
          style={{ width: 80, marginLeft: 12 }}
        >
          <CopyIcon
            width={20}
            height={20}
            style={{ fill: theme.inputFocusBorder }}
          />
        </ContainerButtonNext>
      </div>
      <div>
        {isActive ? (
          <Lottie options={defaultOptions} height={48} width={48} />
        ) : null}
      </div>
    </SubContainer>
  );
};
