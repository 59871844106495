// TextBase.tsx
import styled from "styled-components";

interface TextProps {
  size?: string;
  style?: React.CSSProperties; // Adiciona suporte para estilos adicionais
}

const Text = styled.div<TextProps>`
  font-size: ${(props) => props.size || "16px"};
  letter-spacing: 0.5px;
  ${(props) => props.style && { ...props.style }}// Aplica estilos adicionais
`;

export default Text;
