import { useEffect } from "react";
import { IntegrationsForm } from "../../form/integrations";
import mp from "../../../assets/mercadopago.png";
import telegram from "../../../assets/telegram-removebg-preview.png";
import { ContainerDashboard } from "../styledComponents";
import { useIntegrationContext } from "../../../context/IntegrationContext";
import { MercadoPagoButton } from "../../buttons/MercadoPago";
import { useAuth } from "../../../context/AuthContext";
import { LoginButton } from "@telegram-auth/react";
import { SignInTelegramInfor } from "../../../types/telegram-signin";
import { TextMinimum } from "../../texts/TextStyles";

const ManagerIntegration = () => {
  const {
    fetchTokens,
    tokens,
    botInfor,
    saveTokenDefault,
    updateUserInforTelegram,
    getInforBotTelegram,
  } = useIntegrationContext();
  const { user, userInfor, getUser } = useAuth();

  useEffect(() => {
    fetchTokens();
    getUser();
  }, []);

  useEffect(() => {
    if (tokens["telegram"]) {
      getInforBotTelegram(tokens["telegram"]);
    }
  }, [tokens["telegram"]]);

  console.log({ botInfor });
  return (
    <div style={{ overflow: "auto" }}>
      <ContainerDashboard>
        <IntegrationsForm
          src={telegram}
          placeholder={"Token Bot Telegram"}
          isChecked={Boolean(tokens["telegram"] && botInfor)}
          name={"telegram"}
          type={"password"}
          title={"Passo 1 - Conecte o Bot"}
          value={tokens["telegram"] || ""}
          subTitle={"Conecte o Token do seu Bot criado através do BotFather"}
          onChange={(v) => saveTokenDefault("telegram", v.telegram)}
          DescriptionComponent={
            tokens["telegram"] &&
            botInfor && (
              <div
                style={{
                  marginTop: 12,
                  display: "flex",
                  gap: 6,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <TextMinimum>Conectado</TextMinimum>
                <TextMinimum> | {botInfor?.username}</TextMinimum>
                <TextMinimum> | {botInfor?.first_name} | </TextMinimum>
                <TextMinimum>{botInfor?.id}</TextMinimum>
              </div>
            )
          }
        />
        {tokens["telegram"] && botInfor && botInfor?.username && (
          <IntegrationsForm
            src={telegram}
            isChecked={Boolean(
              tokens["telegram"] && botInfor && botInfor?.username
            )}
            placeholder={"Token Bot Telegram"}
            name={"telegram"}
            type={""}
            title={"Passo 2 - Conecte o Telegram"}
            value={""}
            subTitle={
              "Conecte o telegram do administrador para gerenciar grupos"
            }
            onChange={(v) => saveTokenDefault("telegram", v.telegram)}
            Component={
              <LoginButton
                botUsername={botInfor?.username as string}
                onAuthCallback={(props: SignInTelegramInfor) => {
                  console.log({ ...props });
                  updateUserInforTelegram(props);
                }}
                buttonSize="large" // "large" | "medium" | "small"
                cornerRadius={5} // 0 - 20
                showAvatar={true} // true | false
                lang="pt-br"
              />
            }
            DescriptionComponent={
              userInfor && (
                <div
                  style={{
                    marginTop: 12,
                    display: "flex",
                    gap: 6,
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <TextMinimum>Conectado</TextMinimum>
                  <TextMinimum>
                    {" "}
                    | {userInfor?.telegramInfor?.username}
                  </TextMinimum>
                  <TextMinimum>
                    {" "}
                    | {userInfor?.telegramInfor?.first_name}{" "}
                    {userInfor?.telegramInfor?.last_name} |{" "}
                  </TextMinimum>
                  <TextMinimum>
                    {userInfor?.telegramInfor?.telegramId}
                  </TextMinimum>
                </div>
              )
            }
          />
        )}
        <IntegrationsForm
          src={mp}
          isChecked={!!tokens["mp"]}
          placeholder={""}
          name={"mp"}
          type={"password"}
          value={""}
          title={"Passo 3 - Mercado Pago(PIX)"}
          subTitle={
            "Conecte a sua conta do mercado pago para receber pagamentos PIX"
          }
          linkInfor={"https://www.mercadopago.com.br/developers/panel/app"}
          onChange={(v) => saveTokenDefault("mp", v.mp)}
          Component={<MercadoPagoButton uid={user?.uid as string} />}
        />
      </ContainerDashboard>
    </div>
  );
};

export default ManagerIntegration;
