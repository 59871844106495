import * as Yup from "yup";

export const SignInSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});
const phoneNumberRegex =
  /^(\+\d{1,3}[- ]?)?\(?\d{2,3}\)?[- ]?\d{4,5}[- ]?\d{4}$/;

export const SignUpSchema = Yup.object().shape({
  nickname: Yup.string()
    .matches(
      /^[a-zA-Z0-9]+$/,
      "O apelido não pode conter espaços ou caracteres especiais"
    )
    .min(3, "O apelido deve ter pelo menos 3 caracteres")
    .required("Campo obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
  password: Yup.string()
    .required("Campo obrigatório")
    .min(6, "A senha deve ter no mínimo 6 caracteres"),
  whatsapp: Yup.string()
    .required("Campo obrigatório")
    .matches(phoneNumberRegex, "Número de telefone inválido"),
  // pix: Yup.string().required("Campo obrigatório"),
});
