import api from "../conections/axios";

export const createProduct = async ({
  groupId,
  title,
  description,
}: {
  groupId: number;
  title: string;
  description: string;
}) => {
  try {
    const response = await api.post(`api/createProduct`, {
      title,
      description,
      groupId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProducts = async ({ groupId }: { groupId: number }) => {
  try {
    const response = await api.get(`api/getProducts?groupId=${groupId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createPrice = async ({
  productId,
  unitAmount,
  currency,
  interval,
  typePayment,
}: {
  productId: string;
  unitAmount: number;
  currency: "BRL" | "EUR" | "USD";
  interval: "week" | "month" | "minute";
  typePayment: "pix" | "stripe";
}) => {
  try {
    const response = await api.post(`api/createPrice`, {
      productId,
      unitAmount,
      currency,
      interval,
      typePayment,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPricesProduct = async ({
  productId,
}: {
  productId: any;
}) => {
  try {
    const response = await api.get(
      `api/getPricesProduct?id=${productId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
