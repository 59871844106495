import Lottie from "lottie-react";
import loadingAnimation from "./loading1.json"; // seu arquivo JSON de animação

const Loader = () => (
  <Lottie
    style={{
      margin: 0, // Remove qualquer margem
      padding: 0, // Remove qualquer padding, se houver
      height: "100%", // Ajusta a altura para preencher o contêiner, se necessário
      display: "block", // Remove possíveis margens laterais adicionadas pelo layout inline
    }}
    animationData={loadingAnimation}
    loop={true}
  />
);

export default Loader;
