import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Header } from "../../components/header";
import { ContainerParent } from "../../components/container/ContainerParent";
import { useTheme } from "styled-components";
import SidebarMenu from "../../components/sideBar";
import { HomeDashboard } from "../../components/mainComponents/HomeDashboard";
import ManagerIntegration from "../../components/mainComponents/ManagerIntegration";
import { useAuth } from "../../context/AuthContext";

export interface OptionsConfigType {
  id: number;
  title: string;
  url?: string;
}
export const optionsConfig = [
  { id: 0, title: "Home", url: "" },
  // { id: 1, title: "Packs", url: "/packs" },
  { id: 2, title: "Integrações", url: "/integrations" },
  // { id: 3, title: "Aprender", url: "/aprender" },
] as OptionsConfigType[];

const Dashboard: React.FC = () => {
  // const { logout } = useAuth();
  const { user, logout } = useAuth();
  const theme = useTheme();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = `/${pathSegments[pathSegments.length - 1]}`;

  const navigate = useNavigate();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [optionMenuSelected, setSelectedOptionMenu] =
    useState<OptionsConfigType>({ id: 0, title: "Home" });

  

  useEffect(() => {
    const option = optionsConfig.find((item) => {
      return item.url === lastSegment;
    });
    setSelectedOptionMenu(option || { id: 0, title: "Home", url: "" });
  }, [location.pathname]);

  return (
    <ContainerParent
      style={{
        backgroundColor: theme.subContainerBackground,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SidebarMenu
        logout={logout}
        isOpenMenu={isOpenMenu}
        optionMenuSelected={optionMenuSelected}
        setIsOpenMenu={() => setIsOpenMenu(!isOpenMenu)}
        selectOption={(option: OptionsConfigType) => {
          navigate(`/dashboard${option.url}`);
          setSelectedOptionMenu(option);
          setIsOpenMenu(false);
        }}
        optionsConfig={optionsConfig}
      />
      <ContainerParent
        style={{
          backgroundColor: theme.subContainerBackground,
          gap: 16,
          padding: 16,
        }}
      >
        <Header username={user?.displayName?.toString() || ""} />
        {optionMenuSelected?.id === 0 && <HomeDashboard />}
        {optionMenuSelected?.id === 2 && <ManagerIntegration />}
      </ContainerParent>
    </ContainerParent>
  );
};

export default Dashboard;
