// Paleta de cores
export const colors = {
  pinkFuchsia: "#D20253",
  pinkFushiaRgba: "rgba(210, 2, 83, 0.75)",
  pinkLight: "#FFE8EF",
  blackCharcoal: "#1F2127",
  blackDefault: "#323232",
  lighGray: "#E9E9EA",
  lightWhite: "#FFFFFF",
  grayCultured: "#F8F9FA",
  graySlat: "#7C7C7C",
  grayMedium: "#656565",
  blackMenu: "#05050B",
  greenLight: '#EDF8F7',
  greenText: '#44BCA9'
};

export interface Theme {
  containerBackground: string;
  subContainerBackground: string;
  inputPlaceholderColor: string;
  inputBackground: string;
  buttonBackground: string;
  buttonSelectedBackground: string;
  buttonText: string;
  buttonSelectedText: string;
  buttonHoverBackground: string;
  buttonHoverText: string;
  borderColor: string;
  containerLight: string;
  inputFocusBorder: string;
  buttonLoadingBackground: string;
  textGray: string;
  blackDefault: string;
  containerMenuBackground: string;
}

export interface ThemeProps {
  theme: Theme;
}

// Temas
export const lightTheme = {
  containerBackground: colors.pinkLight,
  subContainerBackground: colors.lightWhite,
  containerMenuBackground: colors.blackMenu,
  inputBackground: colors.grayCultured,
  inputPlaceholderColor: colors.graySlat,
  inputFocusBorder: colors.pinkFuchsia,
  buttonBackground: colors.lighGray,
  buttonSelectedBackground: colors.blackCharcoal,
  buttonText: colors.blackCharcoal,
  buttonSelectedText: colors.lightWhite,
  buttonHoverBackground: colors.blackCharcoal,
  buttonHoverText: colors.lightWhite,
  borderColor: colors.pinkFushiaRgba,
  buttonLoadingBackground: colors.pinkLight,
  textGray: colors.grayMedium,
  blackDefault: colors.blackDefault,
  greenLight: colors.greenLight,
  greenText: colors.greenText
};

export const darkTheme = {
  containerBackground: colors.pinkLight,
  subContainerBackground: colors.lighGray,
  containerMenuBackground: colors.blackMenu,
  placeholderColor: colors.graySlat,
  inputBackground: colors.grayCultured,
  inputFocusBorder: colors.pinkFuchsia,
  buttonBackground: colors.lighGray,
  buttonSelectedBackground: colors.blackCharcoal,
  buttonText: colors.blackCharcoal,
  buttonSelectedText: colors.lightWhite,
  buttonHoverBackground: colors.blackCharcoal,
  buttonHoverText: colors.lightWhite,
  borderColor: colors.pinkFushiaRgba,
  buttonLoadingBackground: colors.pinkLight,
  textGray: colors.grayMedium,
  blackDefault: colors.blackDefault,
  greenLight: colors.greenLight,
  greenText: colors.greenText
};
