import { useEffect, useState } from "react";
import { getValueConfig } from "../firebase/events/remoteConfig";
function useRemoteConfigValue(key: string) {
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const remoteValue = await getValueConfig(key);
      setValue(remoteValue.asBoolean());
    })();
  }, [key]);

  return value;
}

export default useRemoteConfigValue;
