import styled from "styled-components";
import { ThemeProps } from "../../themes/theme";

// Container estilizado para envolver os formulários
export const SubContainer = styled.div<ThemeProps>`
  background-color: ${(props) =>
    props.theme.subContainerBackground}; /* Fundo conforme o tema */
  border-radius: 16px; /* Bordas arredondadas de 16px */
  padding: 24px; /* Espaçamento interno */
  box-shadow: 0 12px 16px rgba(237, 237, 237, 1); /* Sombra leve para profundidade */
  margin: 0 auto; /* Centralizar horizontalmente */
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
