import styled from "styled-components";

export const ContainerButtonNext = styled.div`
  height: 38px;
  width: 38px;
  min-width: 38px;
  background-color: ${(props) => props.theme.subContainerBackground};
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;
