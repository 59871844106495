import { useEffect, useState } from "react";
import Toggle from "../../components/toggle";
import { ContainerParent } from "../../components/container/ContainerParent";
import { useTheme } from "styled-components";
import { useBotContext } from "../../context/BotContext";
import { groupApi } from "../../services/groups";
import { TelegramGroups } from "../../types/telegram-groups";
import { Header } from "../../components/header";
import { useAuth } from "../../context/AuthContext";
import { SubContainer } from "../../components/container";
import {
  TextLarge,
  TextMedium,
  TextMinimum,
} from "../../components/texts/TextStyles";
import Button from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import PriceModal from "../../components/modal/price";

export const ManagerGroups = () => {
  const theme = useTheme();
  const [isModalPriceOpen, setIsModalPriceOpen] = useState(false);
  const navigate = useNavigate();
  const { isActive, startBot, stopBot } = useBotContext();
  const [grupos, setGrupos] = useState<TelegramGroups[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const grupos = await groupApi.getGroups();
        setGrupos(grupos);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <ContainerParent
      style={{
        backgroundColor: theme.subContainerBackground,
        display: "flex",
        flexDirection: "column",
        padding: 18,
      }}
    >
      <Header username={user?.displayName?.toString() || ""} />
      <SubContainer
        style={{
          flex: 1,
          width: "100%",
          marginTop: 24,
          alignItems: "flex-start",
          justifyContent: "flex-start",
          boxSizing: "border-box",
          gap: 24,
        }}
      >
        <TextLarge style={{ fontWeight: "800" }}>
          Gerencie seus grupos VIP do telegram
        </TextLarge>
        <SubContainer style={{ width: "100%", marginBottom: 24 }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Ativar/Desativar Bot</span>
            <Toggle
              initialState={isActive}
              onToggle={() => {
                if (!isActive) {
                  startBot();
                } else {
                  stopBot();
                }
              }}
            />
          </div>
        </SubContainer>
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "start",
            flexWrap: "wrap",
            gap: 12,
            overflow: "auto",
            height: "auto",
            backgroundColor: "transparent",
          }}
        >
          {grupos &&
            grupos?.map((item, index) => {
              return (
                <SubContainer style={{ gap: 12, flex: 1 }} key={index}>
                  <div>
                    <TextMedium
                      style={{
                        fontWeight: 800,
                        marginBottom: 12,
                      }}
                    >
                      Grupo VIP
                    </TextMedium>
                    <TextMedium
                      style={{ fontWeight: 400, color: theme.inputFocusBorder }}
                    >
                      {item.groupInfor.title}
                    </TextMedium>
                    <TextMinimum>{item.groupInfor.groupId}</TextMinimum>
                  </div>
                  <div>
                    <Button
                      style={{ width: "100%", marginBottom: 12 }}
                      title={"Assinantes"}
                      onClick={() => navigate("/subscriptions")}
                    />
                    <Button
                      style={{ width: "100%" }}
                      title={"Preços"}
                      onClick={() => setIsModalPriceOpen(true)}
                    />
                  </div>
                </SubContainer>
              );
            })}
        </div>
      </SubContainer>
      <PriceModal
        isOpen={isModalPriceOpen}
        onClose={() => setIsModalPriceOpen(false)}
        //priceDetails={priceDetails}
        onSave={(data) => console.log(data)}
      />
    </ContainerParent>
  );
};
