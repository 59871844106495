import "./Toggle.css"; // Vamos definir alguns estilos básicos

const Toggle = ({
  initialState = false,
  onToggle,
}: {
  initialState: any;
  onToggle: any;
}) => {
  return (
    <div
      className={`toggle-switch ${initialState ? "active" : ""}`}
      onClick={onToggle}
    >
      <div className="toggle-knob"></div>
    </div>
  );
};

export default Toggle;
