import { Container, Button } from "./styledComponents";

export const optionsSelect = ['entrar', 'cadastro'];

const ButtonSelector = ({
  selected = "entrar",
  setSelected,
}: {
  selected: "entrar" | "cadastro";
  setSelected: (v: "entrar" | "cadastro") => void;
}) => {
  return (
    <Container>
      <Button
        isSelected={selected === "entrar"}
        onClick={() => setSelected("entrar")}
      >
        Entrar
      </Button>
      <Button
        isSelected={selected === "cadastro"}
        onClick={() => setSelected("cadastro")}
      >
        Cadastro
      </Button>
    </Container>
  );
};

export default ButtonSelector;
